import {AppState} from '@/store/state';
import {Component, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {shopDetails} from "@pages/modal/shopdetails";
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Subject, takeUntil} from 'rxjs';
import Swal from "sweetalert2";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";
import {DistributorDetails} from "@pages/modal/distributorDetails";
import {it} from "selenium-webdriver/testing";
import {TraderDetails} from "@pages/modal/traderDetails";


@Component({
    selector: 'shopregister',
    templateUrl: './shopregister.component.html',
    styleUrls: ['./shopregister.component.scss']
})
export class ShopRegister implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    shopDetails: shopDetails | undefined;
    distributorDetails: DistributorDetails | undefined;
    traderDetails: TraderDetails[] = [];
    images: any;
    fileList: File[] = [];
    listOfFiles: any[] = [];
    fileUploadStatus: string = "Exist";
    formData = new FormData();
    isSquare = false;
    isCircle = false;
    isRectangle = false;
    isUpdate = false;
    customerPickupShow = false;
    paymentAcceptShow = false;

    constructor(
        private renderer: Renderer2,
        private store: Store<AppState>,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private toastr: ToastrService) {

    }

    ngOnInit(): void {
        this.getDistributorID();
        this.saveShopDetails.controls['paymentAccept'].setValue("No");
        this.saveShopDetails.controls['orderProcessing'].setValue("No");
        this.saveShopDetails.controls['customerPickup'].setValue("No");
    }

    getDistributorID() {
        this.appService
            .getDistributorId()
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.distributorDetails = data;
                this.traderDetails = data.traderId;
            }, (err: any) => {
                console.log("something went wrong")
            });
    }

    selectTrader(event) {
        const traderId = event.target.value;
        const traderFilter = this.traderDetails.find(item => item.traderId == traderId)
        this.shopDetails = traderFilter.shopDetails;
        console.log(this.shopDetails)
        this.isUpdate = true;
        this.setValueFunction();
        this.getShopLogoImage(this.shopDetails.shopId);
    }

    setValueFunction() {
        this.saveShopDetails.controls["websiteName"].setValue(this.shopDetails.websiteName);
        this.saveShopDetails.controls["shopName"].setValue(this.shopDetails.shopName);
        this.saveShopDetails.controls["storeView"].setValue(this.shopDetails.storeView);
        this.saveShopDetails.controls["streetAddress"].setValue(this.shopDetails.streetAddress);
        this.saveShopDetails.controls["area"].setValue(this.shopDetails.area);
        this.saveShopDetails.controls["city"].setValue(this.shopDetails.city);
        this.saveShopDetails.controls["state"].setValue(this.shopDetails.state);
        this.saveShopDetails.controls["postalCode"].setValue(this.shopDetails.postalCode);
        this.saveShopDetails.controls["country"].setValue(this.shopDetails.country);
        this.saveShopDetails.controls["status"].setValue(this.shopDetails.status);
        this.saveShopDetails.controls["latitude"].setValue(this.shopDetails.latitude);
        this.saveShopDetails.controls["longitude"].setValue(this.shopDetails.longitude);
        this.saveShopDetails.controls["contactNo"].setValue(this.shopDetails.contactNo);
        this.saveShopDetails.controls["msgNo"].setValue(this.shopDetails.msgNo);
        this.saveShopDetails.controls["shopType"].setValue(this.shopDetails.shopType);
        this.saveShopDetails.controls["storeDiscount"].setValue(this.shopDetails.storeDiscount);
        this.saveShopDetails.controls["taxPercentage"].setValue(this.shopDetails.taxPercentage);
        this.saveShopDetails.controls["upiIdOne"].setValue(this.shopDetails.upiIdOne);
        this.saveShopDetails.controls["gstNo"].setValue(this.shopDetails.gstNo);
        this.saveShopDetails.controls["orderProcessing"].setValue(this.shopDetails.orderProcessing);
        this.saveShopDetails.controls["acknowledge"].setValue(this.shopDetails.acknowledge);
        this.saveShopDetails.controls["userId"].setValue(this.shopDetails.userId);
        this.saveShopDetails.controls["shopId"].setValue(this.shopDetails.shopId);
        this.saveShopDetails.controls["shopLogoType"].setValue(this.shopDetails.shopLogoType);
        this.saveShopDetails.controls["shopLogo"].setValue(this.shopDetails.shopLogo);
        this.saveShopDetails.controls["createdAt"].setValue(this.shopDetails.createdAt);
        this.saveShopDetails.controls["createdBy"].setValue(this.shopDetails.createdBy);
        this.saveShopDetails.controls["updatedAt"].setValue(this.shopDetails.updatedAt);
        this.saveShopDetails.controls["modifiedBy"].setValue(this.shopDetails.modifiedBy);
        this.saveShopDetails.controls["appCode"].setValue(this.shopDetails.appCode);
        this.saveShopDetails.controls["traderId"].setValue(this.shopDetails.traderId);
        this.saveShopDetails.controls["distributorId"].setValue(this.shopDetails.distributorId);
        this.saveShopDetails.controls["paymentAccept"].setValue(this.shopDetails.paymentAccept);
        if (this.shopDetails.paymentAccept == "Yes") {
            this.paymentAcceptShow = true;
        } else {
            this.paymentAcceptShow = false;
        }
        this.saveShopDetails.controls["deliveryLeadDate"].setValue(this.shopDetails.deliveryLeadDate);
        const From = this.shopDetails.deliveryLeadTime.split(" -")[0];
        const To = this.shopDetails.deliveryLeadTime.split("- ")[1];
        const Am = this.shopDetails.workingHrs.split(" -")[0];
        const Pm = this.shopDetails.workingHrs.split("- ")[1];
        this.setTimeFormat(Am, Pm, From, To);
        this.saveShopDetails.controls["customerPickup"].setValue(this.shopDetails.customerPickup);
        if (this.shopDetails.customerPickup == "Yes") {
            this.customerPickupShow = true;
        } else {
            this.customerPickupShow = false;
        }
        this.saveShopDetails.controls["packingLeadTime"].setValue(this.shopDetails.packingLeadTime);

        this.onShapeChanged(this.shopDetails.shopLogoType);
    }

    setTimeFormat(Am, Pm, From, To) {
        const [timePart, period] = Am.split(' ');
        const [hours, minutes] = timePart.split(':');
        let hour = parseInt(hours, 10);
        if (period === 'PM' && hour < 12) {
            hour += 12;
        } else if (period === 'AM' && hour === 12) {
            hour = 0;
        }
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');
        this.saveShopDetails.controls["workingHrsAm"].setValue(`${formattedHour}:${formattedMinutes}`);

        const [timePartPm, periodPm] = Pm.split(' ');
        const [hoursPm, minutesPm] = timePartPm.split(':');
        let hourPm = parseInt(hoursPm, 10);
        if (periodPm === 'PM' && hourPm < 12) {
            hourPm += 12;
        } else if (periodPm === 'AM' && hourPm === 12) {
            hourPm = 0;
        }
        const formattedHourPm = hourPm.toString().padStart(2, '0');
        const formattedMinutesPm = minutesPm.padStart(2, '0');
        this.saveShopDetails.controls["workingHrsPm"].setValue(`${formattedHourPm}:${formattedMinutesPm}`);

        if (this.shopDetails.paymentAccept == "Yes") {
            const [timePartFrom, periodFrom] = From.split(' ');
            const [hoursFrom, minutesFrom] = timePartFrom.split(':');
            let hourFrom = parseInt(hoursFrom, 10);
            if (periodFrom === 'PM' && hourFrom < 12) {
                hourFrom += 12;
            } else if (periodFrom === 'AM' && hourFrom === 12) {
                hourFrom = 0;
            }
            const formattedHourFrom = hourFrom.toString().padStart(2, '0');
            const formattedMinutesFrom = minutesFrom.padStart(2, '0');
            this.saveShopDetails.controls["deliveryLeadTimeFrom"].setValue(`${formattedHourFrom}:${formattedMinutesFrom}`);

            const [timePartTo, periodTo] = To.split(' ');
            const [hoursTo, minutesTo] = timePartTo.split(':');
            let hourTo = parseInt(hoursTo, 10);
            if (periodTo === 'PM' && hourTo < 12) {
                hourTo += 12;
            } else if (periodTo === 'AM' && hourTo === 12) {
                hourTo = 0;
            }
            const formattedHourTo = hourTo.toString().padStart(2, '0');
            const formattedMinutesTo = minutesTo.padStart(2, '0');
            this.saveShopDetails.controls["deliveryLeadTimeTo"].setValue(`${formattedHourTo}:${formattedMinutesTo}`);
        }
    }


    saveShopDetails = new UntypedFormGroup({
        websiteName: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        shopName: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        storeView: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        shopLogo: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        streetAddress: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        area: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        city: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        state: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        postalCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        country: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        status: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        latitude: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        longitude: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        contactNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
        msgNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
        shopType: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        storeDiscount: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        taxPercentage: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        upiIdOne: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        gstNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        orderProcessing: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        paymentAccept: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        acknowledge: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        modifiedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        userId: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        shopId: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        shopLogoType: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        traderId: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        distributorId: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        workingHrs: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        workingHrsAm: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        workingHrsPm: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        customerPickup: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        packingLeadTime: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        deliveryLeadDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        deliveryLeadTime: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        deliveryLeadTimeFrom: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        deliveryLeadTimeTo: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
        appCode: new UntypedFormControl("", [Validators.required, Validators.nullValidator,]),
    });

    public shopRegisterErrors = {
        websiteName: "",
        shopName: "",
        storeView: "",
        shopLogo: "",
        streetAddress: "",
        area: "",
        city: "",
        state: "",
        postalCode: "",
        country: "",
        status: "",
        latitude: "",
        longitude: "",
        contactNo: "",
        msgNo: "",
        shopType: "",
        storeDiscount: "",
        taxPercentage: "",
        upiId: "",
        gstNo: "",
        orderProcessing: "",
        paymentAccept: "",
        acknowledge: "",
        workingHrsAm: "",
        workingHrsPm: "",
        deliveryLeadDate: "",
        customerPickup: "",
        packingLeadTime: "",
        deliveryLeadTimeFrom: "",
        deliveryLeadTimeTo: "",
    };

    onSubmit() {
        this.formValidation();
    }

    formValidation() {
        this.shopRegisterErrors.websiteName = "";
        this.shopRegisterErrors.shopName = "";
        this.shopRegisterErrors.storeView = "";
        this.shopRegisterErrors.shopLogo = "";
        this.shopRegisterErrors.streetAddress = "";
        this.shopRegisterErrors.area = "";
        this.shopRegisterErrors.city = "";
        this.shopRegisterErrors.state = "";
        this.shopRegisterErrors.postalCode = "";
        this.shopRegisterErrors.country = "";
        this.shopRegisterErrors.status = "";
        this.shopRegisterErrors.latitude = "";
        this.shopRegisterErrors.longitude = "";
        this.shopRegisterErrors.contactNo = "";
        this.shopRegisterErrors.msgNo = "";
        this.shopRegisterErrors.shopType = "";
        this.shopRegisterErrors.storeDiscount = "";
        this.shopRegisterErrors.taxPercentage = "";
        this.shopRegisterErrors.upiId = "";
        this.shopRegisterErrors.gstNo = "";
        this.shopRegisterErrors.orderProcessing = "";
        this.shopRegisterErrors.paymentAccept = "";
        this.shopRegisterErrors.acknowledge = "";
        this.shopRegisterErrors.workingHrsAm = "";
        this.shopRegisterErrors.workingHrsPm = "";
        this.shopRegisterErrors.deliveryLeadDate = "";
        this.shopRegisterErrors.customerPickup = "";
        this.shopRegisterErrors.packingLeadTime = "";
        this.shopRegisterErrors.deliveryLeadTimeFrom = "";
        this.shopRegisterErrors.deliveryLeadTimeTo = "";

        let hasError = false;

        if (this.saveShopDetails.get("websiteName")?.invalid) {
            this.shopRegisterErrors.websiteName = "*Website Name is Required";
            hasError = true;
        }

        if (this.saveShopDetails.get("shopName")?.invalid) {
            this.shopRegisterErrors.shopName = "*Shop Name is Required";
            hasError = true;
        }

        if (this.saveShopDetails.get("storeView")?.invalid) {
            this.shopRegisterErrors.storeView = "*Shop View Name is Required";
            hasError = true;
        }
       /* if (this.saveShopDetails.get("shopLogo")?.invalid) {
            this.shopRegisterErrors.shopLogo = "*Shop Logo is Required";
            hasError = true;
        }*/
        if (this.saveShopDetails.get("streetAddress")?.invalid) {
            this.shopRegisterErrors.streetAddress = "*Shop Address is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("area")?.invalid) {
            this.shopRegisterErrors.area = "*Area Name is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("city")?.invalid) {
            this.shopRegisterErrors.city = "*City Name is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("state")?.invalid) {
            this.shopRegisterErrors.state = "*State Name is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("postalCode")?.invalid) {
            this.shopRegisterErrors.postalCode = "*Pin Code is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("status")?.invalid) {
            this.shopRegisterErrors.status = "*Shop Status is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("latitude")?.invalid) {
            this.shopRegisterErrors.latitude = "*Latitude is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("longitude")?.invalid) {
            this.shopRegisterErrors.longitude = "*Longitude is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get('contactNo')?.invalid) {
            if (this.saveShopDetails.get('contactNo').errors.required) {
                this.shopRegisterErrors.contactNo = "*Contact Number is required";
            }
            if (this.saveShopDetails.get('contactNo').errors.pattern) {
                this.shopRegisterErrors.contactNo = "*Please, Enter 10 digit Valid Contact Number.";
            }
            hasError = true;
        }
        if (this.saveShopDetails.get('msgNo')?.invalid) {
            if (this.saveShopDetails.get('msgNo').errors.required) {
                this.shopRegisterErrors.msgNo = "*Message Number is required";
            }
            if (this.saveShopDetails.get('msgNo').errors.pattern) {
                this.shopRegisterErrors.msgNo = "*Please, Enter 10 digit Valid Message Number.";
            }
            hasError = true;
        }
        if (this.saveShopDetails.get("shopType")?.invalid) {
            this.shopRegisterErrors.shopType = "*Shop Type is Required";
            hasError = true;
        }
        /*if (this.saveShopDetails.get("storeDiscount")?.invalid) {
            this.shopRegisterErrors.storeDiscount = "*Store Discount is Required";
            hasError = true;
        }*/
        if (this.saveShopDetails.get("taxPercentage")?.invalid) {
            this.shopRegisterErrors.taxPercentage = "*Tax Percentage is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("upiIdOne")?.invalid) {
            this.shopRegisterErrors.upiId = "*UPI ID is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("gstNo")?.invalid) {
            this.shopRegisterErrors.gstNo = "*GST No is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("orderProcessing")?.invalid) {
            this.shopRegisterErrors.orderProcessing = "*Order Processing is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("paymentAccept")?.invalid) {
            this.shopRegisterErrors.paymentAccept = "*Payment Accept is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("acknowledge")?.invalid) {
            this.shopRegisterErrors.acknowledge = "*Terms & Condition is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("workingHrsAm")?.invalid) {
            this.shopRegisterErrors.workingHrsAm = "*Working Hrs Am is Required";
            hasError = true;
        }
        if (this.saveShopDetails.get("customerPickup")?.invalid) {
            this.shopRegisterErrors.customerPickup = "*Customer Pickup is Required";
            hasError = true;
        }

        if (this.customerPickupShow) {
            if (this.saveShopDetails.get("packingLeadTime")?.invalid) {
                this.shopRegisterErrors.packingLeadTime = "*Packing Lead Time is Required";
                hasError = true;
            }
        }

        if (this.paymentAcceptShow) {
            if (this.saveShopDetails.get("deliveryLeadDate")?.invalid) {
                this.shopRegisterErrors.deliveryLeadDate = "*Delivery Lead Date is Required";
                hasError = true;
            }
            if (this.saveShopDetails.get("deliveryLeadTimeFrom")?.invalid) {
                this.shopRegisterErrors.deliveryLeadTimeFrom = "*Delivery Lead From Time is Required";
                hasError = true;
            }
            if (this.saveShopDetails.get("deliveryLeadTimeTo")?.invalid) {
                this.shopRegisterErrors.deliveryLeadTimeTo = "*Delivery Lead To Time is Required";
                hasError = true;
            }
        }
        if (!hasError) {
            if (!this.isUpdate) {
                this.setTimeFormatForSaveAndUpdate('Save');
            } else {
                this.setTimeFormatForSaveAndUpdate('Update');
            }
        }
    }

    reload() {
        window.location.reload();
    }

    selectCustomerPickup(event) {
     const value = event.target.value;
     if (value == "Yes") {
         this.customerPickupShow = true;
     } else {
         this.customerPickupShow = false;
         this.saveShopDetails.controls['packingLeadTime'].setValue('');
     }
    }

    selectPaymentAccept(event) {
        const value = event.target.value;
        if (value == "Yes") {
            this.paymentAcceptShow = true;
        } else {
            this.paymentAcceptShow = false;
            this.saveShopDetails.controls['deliveryLeadDate'].setValue('');
            this.saveShopDetails.controls['deliveryLeadTimeFrom'].setValue('');
            this.saveShopDetails.controls['deliveryLeadTimeTo'].setValue('');
        }
    }

    onShapeChanged(shape: string) {
        if (shape == "square") {
            this.isSquare = true;
            this.isCircle = false;
            this.isRectangle = false;
        } else if (shape == "rectangle") {
            this.isSquare = false;
            this.isCircle = false;
            this.isRectangle = true;
        } else if (shape == "circle") {
            this.isSquare = false;
            this.isCircle = true;
            this.isRectangle = false;
        }
    }

    // Only Integer Numbers are allow functionality
    OnlyNumbers($event) {
        let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
        let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowRight', 'ArrowLeft'];
        if (specialKeys.indexOf($event.key) !== -1) {
            return;
        } else {
            if (regex.test($event.key)) {
                return true;
            } else {
                return false;
            }
        }
    }

    //Float Data types numbers only allow event
    keyPressNumbersWithDecimal(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    // Image preview function for upload a image in the card
    onFileChange(event: any) {
        this.fileList = [];
        for (var i = 0; i <= event.target.files.length - 1; i++) {
            var selectedFile = event.target.files[i];
            console.log("select file size ::" + selectedFile);
            var fileSizeInMb = Math.round((selectedFile.size * 100) / (1024 * 1024)) / 100;
            console.log("select file convert size ::" + fileSizeInMb);
            if (fileSizeInMb <= 10) {
                if (event.target.files && event.target.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        this.images = event.target.result;
                        this.saveShopDetails.patchValue({});
                    };
                    reader.readAsDataURL(event.target.files[0]);
                }
                this.fileList.push(selectedFile);
                this.listOfFiles.push(selectedFile.name);
                this.fileUploadStatus = "Uploaded";
                console.log("File list::", this.fileList)
            } else {
                this.toastr.error("File size is large. Please upload below 10MB")
            }
        }
    }

    setTimeFormatForSaveAndUpdate(status) {
        const OpenAt  = this.saveShopDetails.get('workingHrsAm').value
        const [hours, minutes] = OpenAt.split(':');
        let hour = parseInt(hours, 10);
        const period = hour >= 12 ? 'PM' : 'AM';
        hour = hour % 12 || 12;
        const formattedHour = hour.toString().padStart(2, '0');
        const amValue = `${formattedHour}:${minutes} ${period}`;

        const CloseAt  = this.saveShopDetails.get('workingHrsPm').value
        const [CloseAtHours, CloseAtMinutes] = CloseAt.split(':');
        let CloseAtHour = parseInt(CloseAtHours, 10);
        const CloseAtPeriod = CloseAtHour >= 12 ? 'PM' : 'AM';
        CloseAtHour = CloseAtHour % 12 || 12;
        const CloseAtFormattedHour = CloseAtHour.toString().padStart(2, '0');
        const pmValue = `${CloseAtFormattedHour}:${CloseAtMinutes} ${CloseAtPeriod}`;
        this.saveShopDetails.controls['workingHrs'].setValue(amValue + " - " + pmValue);

        if (this.paymentAcceptShow) {
            const From  = this.saveShopDetails.get('deliveryLeadTimeFrom').value
            const [hours, minutes] = From.split(':');
            let hour = parseInt(hours, 10);
            const period = hour >= 12 ? 'PM' : 'AM';
            hour = hour % 12 || 12;
            const formattedHour = hour.toString().padStart(2, '0');
            const fromValue = `${formattedHour}:${minutes} ${period}`;

            const To  = this.saveShopDetails.get('deliveryLeadTimeTo').value
            const [ToHours, ToMinutes] = To.split(':');
            let ToHour = parseInt(ToHours, 10);
            const ToPeriod = ToHour >= 12 ? 'PM' : 'AM';
            ToHour = ToHour % 12 || 12;
            const ToFormattedHour = ToHour.toString().padStart(2, '0');
            const toValue = `${ToFormattedHour}:${ToMinutes} ${ToPeriod}`;
            this.saveShopDetails.controls["deliveryLeadTime"].setValue(fromValue + " - " + toValue);
        }

        if (status == "Save") {
            this.saveShopRegisterDetails();
        } else if (status == "Update") {
            this.updateShopRegisterDetails();
        }
    }

    saveShopRegisterDetails() {
        const userId = localStorage.getItem('userId');
        this.saveShopDetails.controls['createdBy'].setValue(userId);
        this.saveShopDetails.controls['userId'].setValue(userId);
        this.saveShopDetails.controls['distributorId'].setValue(localStorage.getItem('distributorId'));
        this.spinner.show();
        this.appService
            .saveShopDetails(this.saveShopDetails.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe((saveShopDetails) => {
                console.log('Save shop details::', saveShopDetails);
                if (this.fileList.length > 0) {
                    this.saveShopImageDetails(saveShopDetails.shopDetails.shopId);
                } else {
                    this.successShow();
                    this.spinner.hide();
                }
                this.saveShopDetails.reset();
            }, (error: any) => {
                console.log("error:", error);
                this.spinner.hide();
                if (error && error.error && error.error.msg) {
                    this.toastr.error(error.error.msg);
                } else {
                    this.toastr.error("Something went wrong, please try again");
                }
            });
    }

    updateShopRegisterDetails() {
        this.spinner.show();
        this.appService.updateShopDetails(this.saveShopDetails.value)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                if (this.fileList.length > 0) {
                    this.saveShopImageDetails(this.shopDetails.shopId)
                } else {
                    this.successShow();
                    this.spinner.hide();
                }
            },(error: any) => {
                console.log("error:", error);
                this.spinner.hide();
                if (error && error.error && error.error.msg) {
                    this.toastr.error(error.error.msg);
                } else {
                    this.toastr.error("Something went wrong, please try again");
                }
            });
    }

    saveShopImageDetails(shopId) {
        const formData = new FormData();
        for (const file of this.fileList) {
            formData.append('file', file, file.name);
        }
        this.spinner.show();
        this.appService
            .shopLogoUpload(formData, shopId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                    console.log("Image::", data);
                    this.spinner.hide();
                    this.successShow();
                },
                (error: any) => {
                    console.log("error:", error);
                    this.spinner.hide();
                    if (error && error.error && error.error.msg) {
                        this.toastr.error(error.error.msg);
                    } else {
                        this.toastr.error("Something went wrong, please try again");
                    }
                })
        this.fileList = [];
    }

    //Navigation to product exclusion screen
    NavProductExclusionscreen() {
        this.router.navigate(['/main/exclusion'])
    }

    getShopLogoImage(shopId) {
        this.spinner.show();
        this.appService.getShopLogoImage(shopId)
            .pipe(takeUntil(this.destroy$))
            .subscribe(blob => {
                this.spinner.hide();
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.images = reader.result;
                };
                reader.readAsDataURL(blob);
            }, (err: any) => {
                this.spinner.hide();
                this.toastr.error("Something went wrong, Please try again later");
            })
    }

    // Success alert by sweetalert2
    successShow() {
        let status = ""
        if (!this.isUpdate) {
            status = "Registered"
        } else {
            status = "Updated"
        }
        Swal.fire({
            title: "Shop Details " + status + " Successfully...",
            icon: "success",
            confirmButtonColor: "#80D194",
            cancelButtonColor: "#e64951",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        }).then((result) => {
            if (result.isConfirmed && !this.isUpdate) {
                this.NavProductExclusionscreen();
            } else {
                location.reload();
            }
        });
    }

    // error alert by sweetalert2
    errorShow() {
        Swal.fire({
            title: "Something went wrong Please Try again later",
            icon: "error",
            confirmButtonColor: "#94f2a5",
            cancelButtonColor: "#e64951",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        });
    }

}
