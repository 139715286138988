import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import {ShopRegister} from '@pages/shop-register/shopregister.component';
import {ShopOwnerRegisterComponent} from '@pages/shop-owner-register/shop-owner-register.component';
import {ShopApproval} from '@pages/shop-approval/shopapproval.component';
import {Exclusion} from '@pages/exclusion/exclusion.component';
import {productInclusion} from '@pages/inclusion/inclusion.component';
import {MyShopProductComponent} from "@pages/my-shop-products/my-shop-product.component";
import {OrderProcessingComponent} from '@pages/order-processing/order-processing.component';
import {InVoicePrintComponent} from '@pages/invoice-print/invoice-print.component';
import {TraderOrderProcessingComponent} from "@pages/trader order processing/trader-order-processing.component";
import {PaymentComponent} from "@pages/payment/payment.component";

const routes: Routes = [
    {
        path: 'main',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'profile',
                component: ProfileComponent
            },
            {
                path: 'blank',
                component: BlankComponent
            },
            {
                path: 'shop-register',
                component: ShopRegister
            },
            {
                path: 'sub-menu-1',
                component: SubMenuComponent
            },
            {
                path: 'sub-menu-2',
                component: BlankComponent
            },
            {
                path: 'dash',
                component: DashboardComponent
            },
            {
                path: 'shopapproval',
                component: ShopApproval
            },
            {
                path: 'exclusion',
                component: Exclusion
            },
            {
                path: 'product-inclusion',
                component: productInclusion
            },
            {
                path: 'my-shop-products',
                component: MyShopProductComponent
            },
            {
                path: 'order-processing',
                component: OrderProcessingComponent,
            },
            {
                path: 'trader-order',
                component: TraderOrderProcessingComponent,
            },
            {
                path: 'payment',
                component: PaymentComponent,
            },
        ]
    },
    {
        path: '',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'invoice-print',
        component: InVoicePrintComponent,
    },
    {
        path: 'shop-owner-register',
        component: ShopOwnerRegisterComponent,

    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'recover-password',
        component: RecoverPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
