<section class="content">
    <div class="container-fluid col-lg-12 ml-1">
        <div class="row">
            <div class="row mt-3">
                <mat-card class="order-card">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-12 mt-1">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                            aria-label="Floating label select example" (change)="shopBasedOrderDetails($event)">
                                        <option value="" selected>Select Shop Name</option>
                                        <option *ngFor="let shop of this.shopList" value="{{shop.traderId}}">{{ shop.traderName }}</option>
                                    </select>
                                    <label for="floatingSelect">Shop Name</label>
                                </div>
                            </div>
                            <div class="col-lg col-md-6 col-12 mt-1">
                                <div class="form-floating">
                                    <select class="form-select"
                                            aria-label="Floating label select example" [(ngModel)]="paymentStatusFilter"
                                            (change)="paymentFilter($event, 'status')">
                                        <option value="" selected>Select Payment Status</option>
                                        <option *ngFor="let status of this.paymentStatus">{{ status }}</option>
                                    </select>
                                    <label for="floatingSelect">Payment Status</label>
                                </div>
                            </div>
                            <div class="col-lg col-md-6 col-12 mt-1">
                                <div class="form-floating">
                                    <select class="form-select"
                                            aria-label="Floating label select example" [(ngModel)]="orderTypeFilter"
                                            (change)="paymentFilter($event, 'orderType')">
                                        <option value="" selected>Select Order Type</option>
                                        <option *ngFor="let type of this.orderType">{{ type }}</option>
                                    </select>
                                    <label for="floatingSelect">Order Type</label>
                                </div>
                            </div>
                            <div class="col-lg col-md-6 col-12 mt-1">
                                <div class="form-floating">
                                    <select class="form-select"
                                            aria-label="Floating label select example" [(ngModel)]="dateFilter"
                                            (change)="paymentFilter($event, 'date')">
                                        <option value="" selected>Select Order Date</option>
                                        <option *ngFor="let date of this.uniqueDates">{{ date }}</option>
                                    </select>
                                    <label for="floatingSelect">Order Date</label>
                                </div>
                            </div>
                            <div class="col-lg col-md-6 col-12 mt-1">
                                <div class="form-floating">
                                    <input type="search" class="form-control" placeholder="search"
                                           [(ngModel)]="searchText">
                                    <label class="text-gray">Search</label>
                                </div>
                            </div>
                            <div class="col-lg mt-2">
                                <button class="btn btn-danger mt-2 float-end" (click)="clearFilters()">Clear Filters <img class="img-fluid" alt="clear" src="assets/img/clear-alt.png"></button>
                            </div>
                        </div>

                        <mat-card-content>
                            <div class="container-fluid mt-3">
                                <div class="table-responsive table-style table-bordered">
                                    <table class="table table-green table-head-fixed">
                                        <thead class="h6">
                                        <tr>
                                            <th>S.No</th>
                                            <th>Order ID</th>
                                            <th>Customer Name</th>
                                            <th>Phone Number</th>
                                            <th>Amount</th>
                                            <th>Order Date</th>
                                            <th>Order Type</th>
                                            <th>Payment Type</th>
                                            <th>Payment Status</th>
                                        </tr>
                                        </thead>
                                        <tbody class="text-center" *ngIf="this.ShopPaymentsList.length > 0; else noDataFound">
                                        <tr *ngFor="let payment of this.ShopPaymentsList | filter: searchText; let i = index;">
                                            <td>{{ i + 1 }}</td>
                                            <td>{{ payment.orderUID }}</td>
                                            <td>{{ payment.createdBy }}</td>
                                            <td>{{ payment.phoneNumber }}</td>
                                            <td>{{ payment.payableAmount | currency:'INR':'' }}</td>
                                            <td *ngIf="!isDuplicateDate(payment.orderDate, i)">
                                                {{ getFormattedDate(payment.orderDate) }}
                                            </td>
                                            <td>{{ payment.orderType }}</td>
                                            <td>{{ payment.paymentType }}</td>
                                            <td>
                                            <span class="badge"
                                                  [ngClass]="{'badge-gradient-green': payment.paymentStatus === 'Paid' || payment.paymentStatus === 'Success','badge-gradient-red': payment.paymentStatus !== 'Paid'}">{{ payment.paymentStatus }}</span>
                                            </td>
                                        </tr>
                                        </tbody>
                                        <ng-template #noDataFound>
                                            <tr>
                                                <td colspan="12" class="text-center text-danger">Data Not Found</td>
                                            </tr>
                                        </ng-template>
                                        <ng-template #noDataFoundSearch>
                                            <tr>
                                                <td colspan="12" class="text-center text-danger">Data Not Found</td>
                                            </tr>
                                        </ng-template>
                                    </table>
                                </div>
                            </div>
                        </mat-card-content>
                    </div>
                </mat-card>
                <!--<mat-card class="order-card">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg col-md-6 col-12 mt-1">
                                <div class="form-floating">
                                    <select class="form-select" id="floatingSelect"
                                            aria-label="Floating label select example" [(ngModel)]="paymentStatusFilter"
                                            (change)="paymentFilter($event, 'status')">
                                        <option value="" selected>Select Payment Status</option>
                                        <option *ngFor="let status of this.paymentStatus">{{ status }}</option>
                                    </select>
                                    <label for="floatingSelect">Payment Status</label>
                                </div>
                            </div>
                            <div class="col-lg col-md-6 col-12 mt-1">
                                <div class="form-floating">
                                    <select class="form-select"
                                            aria-label="Floating label select example" [(ngModel)]="paymentTypeFilter"
                                            (change)="paymentFilter($event, 'paymentType')">
                                        <option value="" selected>Select Payment Type</option>
                                        <option *ngFor="let type of this.paymentType">{{ type }}</option>
                                    </select>
                                    <label for="floatingSelect">Payment Type</label>
                                </div>
                            </div>
                            <div class="col-lg col-md-6 col-12 mt-1">
                                <div class="form-floating">
                                    <select class="form-select"
                                            aria-label="Floating label select example" [(ngModel)]="dateFilter"
                                            (change)="paymentFilter($event, 'date')">
                                        <option value="" selected>Select Payment Date</option>
                                        <option *ngFor="let date of this.uniqueDates">{{ date }}</option>
                                    </select>
                                    <label for="floatingSelect">Payment Date</label>
                                </div>
                            </div>
                            <div class="col-lg col-md-6 col-12 mt-1">
                                <div class="form-floating">
                                    <input type="search" class="form-control" placeholder="search"
                                           [(ngModel)]="searchText">
                                    <label class="text-gray">Search</label>
                                </div>
                            </div>
                            <div class="col-lg mt-2">
                                <button class="btn btn-danger mt-2 float-end" (click)="clearFilters()">Clear Filters <img class="img-fluid" alt="clear" src="assets/img/clear-alt.png"></button>
                            </div>
                        </div>
                    </div>
                    <mat-card-content>
                        <div class="container-fluid mt-3">
                            <div class="table-responsive table-style table-bordered">
                                <table class="table table-green table-head-fixed">
                                    <thead class="h6">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Payer Name</th>
                                        <th>Payer Phone Number</th>
                                        <th>Paid Amount</th>
                                        <th>Paid Date</th>
                                        <th>Payment Type</th>
                                        <th>Payment Status</th>
                                    </tr>
                                    </thead>
                                    <tbody class="text-center" *ngIf="this.paymentList.length > 0; else noDataFound">
                                    <tr *ngFor="let payment of this.paymentList | filter: searchText; let i = index;">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ payment.payerName}}</td>
                                        <td>{{ payment.payerMobile}}</td>
                                        <td>{{ payment.paidAmount}}</td>
                                        <td *ngIf="!isDuplicateDate(payment.createdAt, i)">
                                            {{ getFormattedDate(payment.createdAt) }}
                                        </td>
                                        <td>{{ payment.referenceType }}</td>
                                        <td>
                                            <span class="badge"
                                                  [ngClass]="{'badge-gradient-green': payment.paymentStatus === 'PAID' || payment.paymentStatus === 'Success','badge-gradient-red': payment.paymentStatus !== 'PAID'}">{{ payment.paymentStatus }}</span>
                                        </td>
                                    </tr>
                                    </tbody>
                                    <ng-template #noDataFound>
                                        <tr>
                                            <td colspan="12" class="text-center text-danger">Data Not Found</td>
                                        </tr>
                                    </ng-template>
                                    <ng-template #noDataFoundSearch>
                                        <tr>
                                            <td colspan="12" class="text-center text-danger">Data Not Found</td>
                                        </tr>
                                    </ng-template>
                                </table>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>-->
            </div>
        </div>
    </div>
</section>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" type="jelly-box">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>