import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';

@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu;
    role: string = "";
    appCode: string;

    constructor(
        public appService: AppService,
        private store: Store<AppState>
    ) {
    }

    ngOnInit() {
        this.role = localStorage.getItem('role')
        console.log("role::", this.role);
        this.appCode = localStorage.getItem("appCode");
        console.log("app code :", this.appCode);
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;

        if (this.appCode == "APP_CODE_01") {
            this.menu = AnandhamMenu
        } else {
            this.menu = MENU
        }
    }
}

export const MENU = [
   /* {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/main/dash']
    },*/
    {
        name: 'Shops',
        iconClasses: 'fas fa-shopping-bag',
        children: [
            {
                name: 'My Shops',
                iconClasses: 'fas fa-store',
                path: ['/main/my-shop-products']
            },
            {
                name: 'New Shop',
                iconClasses: 'fas fa-store',
                path: ['/main/shop-register']
            },
        ]
    },
    {
        name: 'Products',
        iconClasses: 'fas fa-shopping-cart',
        children: [
            {
                name: 'Product Exclusion',
                iconClasses: 'fa fa-shopping-cart',
                path: ['/main/exclusion']
            },
            {
                name: 'Product Inclusion',
                iconClasses: 'fas fa-cart-plus',
                path: ['/main/product-inclusion']
            },
        ]
    },
    {
        name: 'MY Orders',
        iconClasses:'fas fa-shopping-basket',
        path:['/main/order-processing']
    },

    {
        name: 'Payments',
        iconClasses: 'fas fa-rupee-sign',
        path:['/main/payment']
    }
];

export const AnandhamMenu = [
    /*{
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/main/dash']
    },*/
    {
        name: 'Shops',
        iconClasses: 'fas fa-shopping-bag',
        children: [
            {
                name: 'My Shops',
                iconClasses: 'fas fa-store',
                path: ['/main/my-shop-products']
            },
            {
                name: 'New Shop',
                iconClasses: 'fas fa-store',
                path: ['/main/shop-register']
            },
        ]
    },
    {
        name: 'Products',
        iconClasses: 'fas fa-shopping-cart',
        children: [
            /*{
                name: 'Product Exclusion',
                iconClasses: 'fa fa-shopping-cart',
                path: ['/main/exclusion']
            },*/
            {
                name: 'Product Inclusion',
                iconClasses: 'fas fa-cart-plus',
                path: ['/main/product-inclusion']
            },
        ]
    },
    {
        name: 'MY Orders',
        iconClasses:'fas fa-shopping-basket',
        path:['/main/order-processing']
    },
    /*{
        name: 'Trader Orders',
        iconClasses:'fas fa-shopping-basket',
        path:['/main/trader-order']
    }*/
];
