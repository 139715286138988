import {Component, OnInit, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {productDetails} from "@pages/modal/productdetails";
import {MainCategory} from "@pages/modal/mainCategory";
import {Store, select} from "@ngrx/store";
import {AppState} from "@/store/state";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {Subject, takeUntil} from "rxjs";
import {SubCategory} from "@pages/modal/subCategory";
import Swal from "sweetalert2";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {allShopProductDetails, allShopProductDetailsData} from '@pages/modal/allShopProductDetails';
import {MultiSelectData} from "@pages/modal/multiSelectData";
import {shopDetails} from "@pages/modal/shopdetails";
import {ProductExclusion} from "@pages/modal/productExclusion";
import {NgxSpinnerService} from "ngx-spinner";
import {ToastrService} from "ngx-toastr";


@Component({
    selector: 'exclusion',
    templateUrl: './exclusion.component.html',
    styleUrls: ['./exclusion.component.scss']
})
export class Exclusion implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    categoryList: String[] = [];
    subCategoryList: String[] = [];
    productList: MultiSelectData[] = [];
    productItem: MultiSelectData[] = [];
    product: string[] = [];
    mainCategoryList: MainCategory [] = [];
    subCategory: SubCategory[] = [];
    productDetails: productDetails[] = [];
    currentProductDetails: productDetails[] = [];
    removedProductDetails: productDetails[] = [];
    myKadaiProducts: allShopProductDetails[] = [];
    shopDetails: shopDetails[] = [];
    showShopName: String[] = [];
    localShopList: shopDetails[] = [];
    shopId: number = 0;
    productExclusion: allShopProductDetailsData[] = [];
    shopList: shopDetails[] = [];
    categoryId: number;
    subCategoryId: number;
    traderId: number;

    constructor(private renderer: Renderer2,
                private store: Store<AppState>,
                private appService: AppService,
                private router: Router,
                private spinner: NgxSpinnerService,
                private toaster: ToastrService
    ) {
    }


    productExclusionDetails = new UntypedFormGroup({
        shopId: new UntypedFormControl("", Validators.required),
        shopName: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        productName: new UntypedFormControl("", Validators.required),
        category: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        subcategory: new UntypedFormControl("", [
            Validators.required,
            Validators.nullValidator,
        ]),
        productId: new UntypedFormControl("", Validators.required),
    });

    public productExclusionErrors = {
        shopid: "",
        shopname: "",
        productname: "",
        productuom: "",
        productprice: "",
        category: "",
        subcategory: "",
    }

    formValidation() {
        this.productExclusionErrors.shopid = "";
        this.productExclusionErrors.shopname = "";
        this.productExclusionErrors.productname = "";
        this.productExclusionErrors.productuom = "";
        this.productExclusionErrors.productprice = "";
        this.productExclusionErrors.category = "";
        this.productExclusionErrors.subcategory = "";
        let hasError = false;

        if (this.productExclusionDetails.get("shopid")?.invalid) {
            this.productExclusionErrors.shopid = "*Shop ID is Required";
            hasError = true;
        }
        if (this.productExclusionDetails.get("shopname")?.invalid) {
            this.productExclusionErrors.shopname = "*Shop Name is Required";
            hasError = true;
        }
        if (this.productExclusionDetails.get("productname")?.invalid) {
            this.productExclusionErrors.productname = "*Product Name is Required";
            hasError = true;
        }
        if (this.productExclusionDetails.get("productuom")?.invalid) {
            this.productExclusionErrors.productuom = "*Product UOM is Required";
            hasError = true;
        }
        if (this.productExclusionDetails.get("productprice")?.invalid) {
            this.productExclusionErrors.productprice = "*Product Price is Required";
            hasError = true;
        }
        if (this.productExclusionDetails.get("category")?.invalid) {
            this.productExclusionErrors.category = "*Category is Required";
            hasError = true;
        }
        if (this.productExclusionDetails.get("subCategory")?.invalid) {
            this.productExclusionErrors.subcategory = "*Sub Category is Required";
            hasError = true;
        }

        if (!hasError) {
            this.removeProduct();
        }
    }

    onSubmit() {
        this.formValidation();
    }


    getProduct() {
        var catList: String[] = [];
        this.spinner.show();
        this.appService
            .getCategory()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Product values::", data);
                this.mainCategoryList = data;
                this.spinner.hide();
                for (let category of this.mainCategoryList) {
                    catList.push(category.catName);
                }
                catList.sort();
                this.categoryList = catList.filter(function (elem, index, self) {
                    return index === self.indexOf(elem);
                });
            }, (error: any) => {
                this.spinner.hide();
                this.toaster.error("Something went wrong for get products")
            });
    }

    // Main product  Category functionality
    selectCategory(event) {
        const value = event.target.value;
        this.categoryId = value;
        var catId: Number[] = [];
        var subCategory: String [] = [];
        if (value != "") {
            for (var id of this.mainCategoryList) {
                if (value == id.catId) {
                    catId.push(id.catId)
                    this.spinner.show();
                    this.appService
                        .getSubCatID(catId)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((data) => {
                            console.log("subCategory Details ::", data);
                            // @ts-ignore
                            this.subCategory = data;
                            this.spinner.hide();
                            for (let subCat of this.subCategory) {
                                subCategory.push(subCat.subCatName);
                            }
                            subCategory.sort();
                            this.subCategoryList = subCategory.filter(function (elem, index, self) {
                                return index === self.indexOf(elem);
                            });
                        }, (error: any) => {
                            this.spinner.hide();
                            this.toaster.error("Something went wrong for get Product Category")
                        });
                }
            }
        }
    }

    // remove product function when click the Remove button
    removeProduct() {
        Swal.fire({
            title: 'Do you want to exclude unsale products from your shop?',
            showDenyButton: true,
            confirmButtonText: 'Remove',
            denyButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                var exclusionDetails: allShopProductDetailsData[] = [];
                for (const pro of this.currentProductDetails) {
                    if (!pro.isRemoved) {
                        const exclusion: allShopProductDetailsData = new allShopProductDetailsData();
                        exclusion.enabled = true;
                        exclusion.productsId = pro.productId;
                        exclusion.productName = pro.productName;
                        exclusion.shopProductMRP = 0;
                        exclusion.shopProductSRP = 0;
                        exclusion.subProductCategoryId = this.subCategoryId;
                        exclusion.mainProductCategoryId = this.categoryId;
                        exclusion.appCode = localStorage.getItem('appCode');
                        exclusion.status = "Active";
                        exclusion.traderId = this.traderId;
                        exclusion.distributorId = Number(localStorage.getItem("distributorId"));
                        exclusionDetails.push(exclusion);
                    }
                }
                this.productExclusion = exclusionDetails;
                console.log("Exclusion Details::", this.productExclusion);
                this.spinner.show();
                this.appService
                    .saveProduct(this.productExclusion)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((data) => {
                            console.log('My Kadai Product::', data);
                            this.myKadaiProducts = data;
                            setTimeout(() => {
                                this.spinner.hide();
                            }, 10000);
                            this.successShow();
                        }
                        ,
                        (err: any) => {
                            this.spinner.hide();
                            //error alert show by sweetalert2
                            Swal.fire('Product Not Removed Please try again...!', '', 'error')
                        },
                        () => console.log("HTTP request completed.")
                    )
            }
        })
    }

    // Sub product category functionality
    selectSubCategory(event) {
        const value = event.target.value;
        console.log("value::", value)
        this.subCategoryId = value;
        var subCatId: Number[] = [];
        var localProductList: MultiSelectData[] = [];
        if (value != '') {
            for (let product of this.subCategory) {
                console.log("value::", value)
                if (value == product.subCatId) {
                    subCatId.push(product.subCatId)
                    this.spinner.show();
                    this.appService
                        .getProductDetails(subCatId)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((data) => {
                            console.log("product Details ::", data);
                            this.productDetails = data;
                            this.currentProductDetails = data;
                            this.spinner.hide();
                            for (let pro of this.productDetails) {
                                localProductList.push(
                                    new MultiSelectData(pro.productId, pro.productName));
                            }
                            this.productList = localProductList
                            this.productItem = localProductList
                        }, (error: any) => {
                            this.spinner.hide();
                            this.toaster.error("Something went wrong for get Product Sub Category")
                        });
                }
            }
        }

    }

    onItemSelect(item: any[]) {
        console.log(item);
    }

    onItemDeSelect(items: any[]) {
        console.log(items);
    }

    onSelectAll(items: any) {
        console.log(items);
    }

    DropDownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: "id",
        textField: "name",
        selectAllText: "Select All",
        unSelectAllText: "UnSelect All",
        itemsShowLimit: 10,
        allowSearchFilter: true,
    };

    ngOnInit(): void {
        this.getProduct();
        this.getShopDetails();

    }

    getShopDetails() {
        this.appService.getShopDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                    console.log("Shop Details::", data);
                    // @ts-ignore
                    this.shopList = data;
                },
                (error: any) => {
                    this.spinner.hide();
                    this.toaster.error("Something went wrong for get Shop Details")
                })
    }

    // When click the ShopName based  ShoID show in the shopID field
    getShopId(event) {
        const value = event.target.value;
        this.traderId = value;
        console.log("traderId::", this.traderId)
        var shopId: number[] = [];
        if (value != null) {
            for (let shop of this.shopDetails) {
                if (value == shop.shopId) {
                    shopId.push(shop.shopId)
                }
            }
            this.shopId = shopId[0];
            this.productExclusionDetails.controls['shopId'].setValue(this.shopId)
        }
    }

    // success alert popup by sweetalert2
    successShow() {
        Swal.fire({
            title: "UnSale Products Removed Successfully...",
            icon: "success",
            confirmButtonColor: "#80D194",
            cancelButtonColor: "#e64951",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                this.NavProductInclusionscreen();
            }
        });
    }

    // navigate to the product inclusion page
    NavProductInclusionscreen() {
        this.router.navigate(['/main/product-inclusion'])
    }

    /* // Get UserID Based Shop Details showed in the ShopName - Dropdown
    getShopDetails() {
        this.spinner.show();
        this.appService.getShopDetails()
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                console.log("Shop Details::", data);
                // @ts-ignore
                this.shopDetails = data;
                this.spinner.hide();
                var shopList: shopDetails[] = [];
                for (var shop of this.shopDetails) {
                    var userId = localStorage.getItem('userId');
                    // @ts-ignore
                    if (userId == shop.createdBy) {
                        shopList.push(shop)
                        console.log("Shop List::", shopList)
                    }
                    this.localShopList = shopList
                    var shopName: String[] = [];
                    for (var name of shopList) {
                        shopName.push(name.shopName)
                    }
                    this.showShopName = shopName;
                }
            })
    }*/

    clickToRemove(event, productId: number) {
        if (this.removedProductDetails.map(item => item.productId).includes(productId)) {
            this.removedProductDetails = this.removedProductDetails.filter(item => item.productId != productId);
            this.currentProductDetails.find(item => item.productId == productId).isRemoved = false;
        } else {
            this.removedProductDetails.push(this.currentProductDetails.find(item => item.productId == productId));
            this.currentProductDetails.find(item => item.productId == productId).isRemoved = true;
        }
    }

}
