import {SubCategory} from "@pages/modal/subCategory";

export interface MainCategory {
    catId : Number;
    catName : String;
    activeStatus : String;
    createdBy : String;
    modifiedBy : String;
    createdAt : Number;
    modifiedAt : Number;
   /* subCategory : SubCategory[];*/
}

export class MainCategoryClass {
    catId : Number;
    catName : String;
    activeStatus : String;
    createdBy : String;
    modifiedBy : String;
    createdAt : Number;
    modifiedAt : Number;
    /* subCategory : SubCategory[];*/
}
