export interface productDetails {
    productId: number;
    productName: string;
    productImage: string;
    productDesc: string;
    minOrderQty: number;
    maxOrderQty: number;
    addedBy: string;
    mainProductCategory: number;
    subProductCategory: number;
    modifiedAt:number;
    enabled: boolean;
    isRemoved: boolean;
}


export class productDetailsData {
    productId: number;
    productName: string;
    addedBy: string;
    mainProductCategory: number;
    subProductCategory: number;
    enabled: boolean;
}
