<div class="container-fluid">
    <div class="row">
        <div class="col-12 col-md-6 five" style="align-self: center">
            <h1>Shop Register</h1>
        </div>
        <div class="col-12 col-md-6 mt-1">
            <div class="form-floating">
                <select class="form-select" (change)="selectTrader($event)">
                    <option value="" disabled selected>Select Shop Name</option>
                    <option *ngFor="let trader of traderDetails" value="{{trader.traderId}}">{{trader.traderName}}</option>
                </select>
                <label>Select Shop Name</label>
            </div>
        </div>
    </div>
    <form (ngSubmit)="onSubmit()" [formGroup]="saveShopDetails" id="saveShopDetails">
        <div class="row mt-3">
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="websiteName" placeholder="Enter Your Website Name">
                    <label>Website Name<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.websiteName"
                          class="error-text">{{shopRegisterErrors.websiteName}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="shopName" placeholder="Enter Your Shop Name">
                    <label>Shop Name<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.shopName"
                          class="error-text">{{shopRegisterErrors.shopName}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="storeView" placeholder="Enter Your Store View Name">
                    <label>Store View Name<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.storeView"
                          class="error-text">{{shopRegisterErrors.storeView}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="streetAddress" placeholder="Enter Your Shop Address">
                    <label>Shop Address<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.streetAddress"
                          class="error-text">{{shopRegisterErrors.streetAddress}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="area" placeholder="Enter Your Area Name">
                    <label>Area<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.area"
                          class="error-text">{{shopRegisterErrors.area}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="city" placeholder="Enter Your City">
                    <label>City<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.city"
                          class="error-text">{{shopRegisterErrors.city}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="state" placeholder="Enter Your State Name">
                    <label>State<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.state"
                          class="error-text">{{shopRegisterErrors.state}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="postalCode" placeholder="Enter Your Zip/Postal Code"
                           (keypress)="OnlyNumbers($event)" maxlength="6">
                    <label>Zip/Postal Code<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.postalCode"
                          class="error-text">{{shopRegisterErrors.postalCode}}</span>
                </div>
            </div>
            <!--<div class="col-md-3 mt-2">
                <div class="form-floating">
                    <select class="form-select" formControlName="country">
                        <option class="placeholder" value="" disabled selected>Select Your Country</option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo">Congo, the Democratic Republic of the</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                        <option value="Croatia">Croatia (Hrvatska)</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="France Metropolitan">France, Metropolitan</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Territories">French Southern Territories</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                        <option value="Holy See">Holy See (Vatican City State)</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India" selected>India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran">Iran (Islamic Republic of)</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea">Korea, Republic of</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao">Lao People's Democratic Republic</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia">Micronesia, Federated States of</option>
                        <option value="Moldova">Moldova, Republic of</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russian Federation</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                        <option value="Saint LUCIA">Saint LUCIA</option>
                        <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia (Slovak Republic)</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                        <option value="Span">Spain</option>
                        <option value="SriLanka">Sri Lanka</option>
                        <option value="St. Helena">St. Helena</option>
                        <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syrian Arab Republic</option>
                        <option value="Taiwan">Taiwan, Province of China</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania, United Republic of</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos">Turks and Caicos Islands</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Viet Nam</option>
                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                        <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                        <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                    </select>
                    <label>Country<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.country"
                          class="error-text">{{shopRegisterErrors.country}}</span>
                </div>
            </div>-->
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <select class="form-select" formControlName="status">
                        <option value="" disabled selected>Select Shop Status</option>
                        <option value="Active">Active</option>
                        <option value="InActive">InActive</option>
                    </select>
                    <label>Shop Status<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.status"
                          class="error-text">{{shopRegisterErrors.status}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="latitude" placeholder="Enter Your Latitude Point">
                    <label>Latitude Point<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.latitude"
                          class="error-text">{{shopRegisterErrors.latitude}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="longitude" placeholder="Enter Your Longitude Point">
                    <label>Longitude Point<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.longitude"
                          class="error-text">{{shopRegisterErrors.longitude}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="contactNo" placeholder="Enter Your Contact Number" maxlength="10" (keypress)="OnlyNumbers($event)">
                    <label>Contact Number<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.contactNo"
                          class="error-text">{{shopRegisterErrors.contactNo}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="msgNo" placeholder="Enter Your Messaging Number" maxlength="10" (keypress)="OnlyNumbers($event)">
                    <label>Messaging Number<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.msgNo"
                          class="error-text">{{shopRegisterErrors.msgNo}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="shopType" placeholder="Enter Your Shop Type">
                    <label>Type of The Shop<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.shopType"
                          class="error-text">{{shopRegisterErrors.shopType}}</span>
                </div>
            </div>
            <!--<div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="storeDiscount" placeholder="Enter Your Store Discount">
                    <label>Store Discount<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.storeDiscount"
                          class="error-text">{{shopRegisterErrors.storeDiscount}}</span>
                </div>
            </div>-->
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="taxPercentage" placeholder="Enter Your Tax Percentage">
                    <label>Tax Percentage<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.taxPercentage"
                          class="error-text">{{shopRegisterErrors.taxPercentage}}</span>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="upiIdOne" placeholder="Enter Your UPI ID">
                    <label>UPI ID<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.upiId"
                          class="error-text">{{shopRegisterErrors.upiId}}</span>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="gstNo" placeholder="Enter Your GST No">
                    <label>GST No<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.gstNo"
                          class="error-text">{{shopRegisterErrors.gstNo}}</span>
                </div>
            </div>
            <!--<div class="col-md-3 mt-2">
                <div class="row col-md-12" style="margin: 1px; padding: 1px">
                    <div class="form-floating col-md-7 p-0">
                        <input type="text" (keypress)="OnlyNumbers($event)" class="form-control"
                               formControlName="workingHrs" maxlength="3"
                               placeholder="Working Hrs">
                        <label>Working Hrs <span class="text-danger">*</span></label>
                        <span class="text-danger"
                              *ngIf="shopRegisterErrors.workingHrs">{{shopRegisterErrors.workingHrs}}</span>
                    </div>
                    <div class="form-floating col-md-5 p-0">
                        <input class="form-control p-4" disabled value="Hours">
                    </div>
                </div>
            </div>-->
            <div class="col-md-3 mt-2">
                <div class="row col-md-12" style="margin: 1px; padding: 1px">
                    <div class="form-floating col-md-6 p-lg-0">
                        <input type="time" (keypress)="OnlyNumbers($event)" class="form-control"
                               formControlName="workingHrsAm" placeholder="AM">
                        <label>Open at AM<span class="text-danger">*</span></label>
                        <span class="error-text"
                              *ngIf="shopRegisterErrors.workingHrsAm">{{shopRegisterErrors.workingHrsAm}}</span>
                    </div>
                    <div class="form-floating col-md-6 p-0">
                        <input type="time" (keypress)="OnlyNumbers($event)" class="form-control"
                               formControlName="workingHrsPm" placeholder="PM">
                        <label>Close at PM<span class="text-danger">*</span></label>
                        <span class="error-text"
                              *ngIf="shopRegisterErrors.workingHrsPm">{{shopRegisterErrors.workingHrsPm}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mt-2">
                <label>Customer Pickup<span class="text-danger">*</span></label>
                <div class="row ml-3">
                    <div class="col-md-6 col-6 form-check">
                        <input class="form-check-input" type="radio" formControlName="customerPickup"
                               value="Yes" (change)="selectCustomerPickup($event)">
                        <label class="form-check-label">Yes</label>
                    </div>
                    <div class="col-md-6 col-6 form-check">
                        <input class="form-check-input" type="radio" formControlName="customerPickup"
                               value="No" (change)="selectCustomerPickup($event)">
                        <label class="form-check-label">No
                        </label>
                    </div>
                </div>
                <span *ngIf="shopRegisterErrors.customerPickup"
                      class="error-text">{{shopRegisterErrors.customerPickup}}</span>
            </div>

            <div *ngIf="customerPickupShow" class="col-md-3 mt-2">
                <div class="form-floating">
                    <input class="form-control" formControlName="packingLeadTime" placeholder="Enter Pickup Lead Time in Hrs"
                           (keypress)="OnlyNumbers($event)" maxlength="2">
                    <label>Pickup Lead Time Hrs<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.packingLeadTime"
                          class="error-text">{{shopRegisterErrors.packingLeadTime}}</span>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <label>Payment Before Accept<span class="text-danger">*</span></label>
                <div class="row ml-3">
                    <div class="col-md-6 col-6 form-check">
                        <input class="form-check-input" type="radio" formControlName="paymentAccept"
                               value="Yes" (change)="selectPaymentAccept($event)">
                        <label class="form-check-label">Yes</label>
                    </div>
                    <div class="col-md-6 col-6 form-check">
                        <input class="form-check-input" type="radio" formControlName="paymentAccept"
                               value="No" (change)="selectPaymentAccept($event)">
                        <label class="form-check-label">No
                        </label>
                    </div>
                </div>
                <span *ngIf="shopRegisterErrors.paymentAccept"
                      class="error-text">{{shopRegisterErrors.paymentAccept}}</span>
            </div>

            <div *ngIf="paymentAcceptShow" class="col-md-3 mt-2">
                <div class="form-floating">
                    <select class="form-select" formControlName="deliveryLeadDate">
                        <option value="" selected disabled>Select Lead Date</option>
                        <option value="0">Same Day</option>
                        <option value="1">1 Day</option>
                        <option value="2">2 Day</option>
                        <option value="3">3 Day</option>
                        <option value="4">4 Day</option>
                        <option value="5">5 Day</option>
                        <option value="6">6 Day</option>
                        <option value="7">7 Day</option>
                        <option value="8">8 Day</option>
                        <option value="9">9 Day</option>
                        <option value="10">10 Day</option>
                    </select>
                    <label>Delivery Lead Date<span class="text-danger">*</span></label>
                    <span *ngIf="shopRegisterErrors.deliveryLeadDate"
                          class="error-text">{{shopRegisterErrors.deliveryLeadDate}}</span>
                </div>
            </div>

            <div *ngIf="paymentAcceptShow" class="col-md-3 mt-2">
                <div class="row col-md-12" style="margin: 1px; padding: 1px">
                    <div class="form-floating col-md-6 p-0">
                        <input type="time" (keypress)="OnlyNumbers($event)" class="form-control"
                               formControlName="deliveryLeadTimeFrom"
                               placeholder="From">
                        <label>From Time<span class="text-danger">*</span></label>
                        <span class="error-text"
                              *ngIf="shopRegisterErrors.deliveryLeadTimeFrom">{{shopRegisterErrors.deliveryLeadTimeFrom}}</span>
                    </div>
                    <div class="form-floating col-md-6 p-0">
                        <input type="time" (keypress)="OnlyNumbers($event)" class="form-control"
                               formControlName="deliveryLeadTimeTo"
                               placeholder="To">
                        <label>To Time<span class="text-danger">*</span></label>
                        <span class="error-text"
                              *ngIf="shopRegisterErrors.deliveryLeadTimeTo">{{shopRegisterErrors.deliveryLeadTimeTo}}</span>
                    </div>
                </div>
            </div>

            <div class="col-md-3 mt-2">
                <label>Order Processing<span class="text-danger">*</span></label>
                <div class="row ml-3">
                    <div class="col-md-6 col-6 form-check">
                        <input class="form-check-input" type="radio" id="orderProcessing1"
                               formControlName="orderProcessing" value="Yes">
                        <label class="form-check-label">Yes</label>
                    </div>
                    <div class="col-md-6 col-6 form-check">
                        <input class="form-check-input" type="radio" id="orderProcessing2"
                               formControlName="orderProcessing" value="No">
                        <label class="form-check-label">No</label>
                    </div>
                </div>
                <span *ngIf="shopRegisterErrors.orderProcessing"
                      class="error-text">{{shopRegisterErrors.orderProcessing}}</span>
            </div>

            <div class="col-md-3 mt-2">
                <div class="form-group">
                    <label>Shop Image</label>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-check">
                                <input type="radio" class="form-check-input" formControlName="shopLogoType" id="circle"
                                       value="circle" (change)="onShapeChanged('circle')"><label
                                    class=""><small
                                    class="">Circle(100*100px)</small></label>
                            </div>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" formControlName="shopLogoType" id="rectangle"
                                       value="rectangle" (change)="onShapeChanged('rectangle')"><label
                                    class=""><small
                                    class="">Rectangle(200*100px)</small></label>
                            </div>
                            <div class="form-check">
                                <input type="radio" class="form-check-input" formControlName="shopLogoType" id="square"
                                       value="square" (change)="onShapeChanged('square')"><label
                                    class=""><small
                                    class="">Square(100*100px)</small></label>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div *ngIf="isCircle" class="card"
                                 style="width: 100px; height: 100px;margin-left: 10px; border-radius: 50%">
                                <div *ngIf="images" style="width: 100px; height: 100px; border-radius: 50%">
                                    <img class="image-preview circle" [src]="images" width="100" height="100">
                                </div>
                            </div>
                            <div *ngIf="isRectangle" class="card"
                                 style="width: 200px; height: 100px; margin-left: 10px;">
                                <div *ngIf="images" style="width: 200px; height: 100px;">
                                    <img class="image-preview" [src]="images" width="200" height="100">
                                </div>
                            </div>
                            <div *ngIf="isSquare" class="card" style="width: 100px; height: 100px;margin-left: 10px;">
                                <div *ngIf="images" style="width: 100px; height: 100px;">
                                    <img class="image-preview" [src]="images" width="100" height="100">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="shopLogo"
                                       (change)="onFileChange($event)">
                                <!--<div *ngIf="shopRegisterErrors.shopLogo" class="error-text">
                                    {{shopRegisterErrors.shopLogo}}
                                </div>-->
                                <label class="custom-file-label" for="shopLogo">Choose file</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-8 mt-5">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="Accepted" id="acknowledge"
                           formControlName="acknowledge">
                    <label class="form-check-label">
                        Click here to Acknowledge the Terms & Conditions
                    </label>
                    <span *ngIf="shopRegisterErrors.acknowledge"
                          class="error-text">{{shopRegisterErrors.acknowledge}}</span>
                </div>
            </div>
        </div>
        <div class="row mt-2 mb-2">
            <div class="col-md-4"></div>
            <div class="col-md-3 mt-2">
                <div class="row justify-content-center">
                    <button type="submit" style="width:100px;" class="btn btn-primary">{{isUpdate ? "Update" : "Register"}}</button>
                    <button *ngIf="!isUpdate" type="button" style="margin-left:5px; width: 100px;" class="btn btn-secondary"
                            (click)="reload();">Reset
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium">
    <div class="loader">
        <div class="inner one"></div>
        <div class="inner two"></div>
        <div class="inner three"></div>
    </div>
</ngx-spinner>
