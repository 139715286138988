import {MainCategory} from "@pages/modal/mainCategory";

export interface SubCategory {
    activeStatus: string;
    createdAt: string;
    createdBy: string;
    modifiedAt: number;
    modifiedBy: string
    subCatId: number;
    subCatName: string;
    mainCategory: MainCategory;
}

export class SubCategoryClass {
    activeStatus: string;
    createdAt: string;
    createdBy: string;
    modifiedAt: number;
    modifiedBy: string
    subCatId: number;
    subCatName: string;
    mainCategory: MainCategory;
}
