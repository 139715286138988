import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import {UntypedFormGroup} from '@angular/forms';
import {UserDetails} from "@pages/modal/userDetails";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ConfigService} from './configService';
import {shopDetails, ShopRegisterResponse} from "@pages/modal/shopdetails";
import {LocalDataService} from "@services/localDataService";
import {productDetails} from "@pages/modal/productdetails";
import {MainCategory} from "@pages/modal/mainCategory";
import {SubCategory} from "@pages/modal/subCategory";
import {allShopProductDetails, allShopProductDetailsData} from '@pages/modal/allShopProductDetails';
import {LoginRequest} from "@services/loginRequest";
import {LoginResponse} from "@services/loginResponse";
import {ProductExclusion} from "@pages/modal/productExclusion";
import {VendorOrderDetails} from '@pages/modal/vendorOrderDetails';
import {DistributorDetails} from "@pages/modal/distributorDetails";
import {AddProductDto} from "@pages/modal/addProductDto";
import {Order} from "@pages/modal/order/Order";
import {DistributorOrderDetails} from "@pages/modal/order/DistributorOrderDetails";
import {InvoiceDetails} from "@pages/modal/invoice/InvoiceDetails";

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;
    endpoint = "https://tomcat3.varnik.cloud:8443/mykadai-ws";
    // endpoint = "http://136.233.82.212:8080/mykadai-ws";
    // endpoint = "http://localhost:8084"

    constructor(private router: Router, private toastr: ToastrService,
                private http: HttpClient, private configService: ConfigService,
                private localData: LocalDataService) {
    }

    async loginByAuth({email, password}) {
        try {
            const token = await Gatekeeper.loginByAuth(email, password);
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Login success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByAuth({email, password}) {
        try {
            const token = await Gatekeeper.registerByAuth(email, password);
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByGoogle() {
        try {
            const token = await Gatekeeper.loginByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Login success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByGoogle() {
        try {
            const token = await Gatekeeper.registerByGoogle();
            localStorage.setItem('token', token);
            await this.getProfile();
            this.router.navigate(['/']);
            this.toastr.success('Register success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async loginByFacebook() {
        try {
            // const token = await Gatekeeper.loginByFacebook();
            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    //'Authorization': 'Bearer '+this.token,
                    'Access-Control-Allow-Origin': '*'
                }),
            };
            //   let add_url=this.baseURL+'/signin/facebook'
            // const a = await this.http.post<string>(add_url,null,httpOptions)
            // .subscribe((data) => {
            //   console.log(data);
            // });

            // return this.http.post(this.baseURL + '/signin/facebook', {
            //   displayName: this.user.displayName,
            //   email: this.user.email,
            //   password: this.user.password,
            //   matchingPassword: this.user.matchingPassword,
            //   socialProvider: 'LOCAL'
            // }, httpOptions);

            console.log("asd")
            const url = await this.http.post(this.endpoint + '/signin/facebook', httpOptions).subscribe(url => {
                window.location.href = url as string;


            });
            console.log(url)
            //   window.location.href = fb1 as string;

            // localStorage.setItem('token', token);
            // await this.getProfile();
            //  this.router.navigate(['/']);
            this.toastr.success('Login success');
        } catch (error) {
            this.toastr.error(error.message);
        }
    }

    async registerByFacebook() {
        // try {
        //     const token = await Gatekeeper.registerByFacebook();
        //     localStorage.setItem('token', token);
        //     await this.getProfile();
        //     this.router.navigate(['/']);
        //     this.toastr.success('Register success');
        // } catch (error) {
        //     this.toastr.error(error.message);
        // }
    }

    async getProfile() {
        try {
            this.user = this.localData.getHeaders();
        } catch (error) {
            this.logout();
            throw error;
        }
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('gatekeeper_token');
        localStorage.removeItem('authToken');
        localStorage.removeItem('role');
        localStorage.removeItem('userType');
        localStorage.removeItem('userName');
        localStorage.removeItem('userId');
        this.user = null;
        this.router.navigate(['/login']);
    }

    //post api for shop owner details
    saveshopOwnDetails(userForm: UntypedFormGroup) {
        return this.http.post<UserDetails[]>(
            this.endpoint + "/auth/registerUser", userForm,
            {headers: this.localData.signinHeaders()}
        );
    }

    saveShopDetails(userForm: UntypedFormGroup) {
        return this.http.post<ShopRegisterResponse>(
            this.endpoint + "/api/shop", userForm,
            {headers: this.localData.getHeaders()}
        );
    }

    updateShopDetails(userForm: UntypedFormGroup) {
        return this.http.put<any>(
            this.endpoint + "/api/shop", userForm,
            {headers: this.localData.getHeaders()}
        );
    }

    //Save New Product Details
    saveNewProductInclusion(addProduct: any) {
        console.log("Trader Id::", addProduct.traderId);
        return this.http.post<AddProductDto>(
            this.endpoint + "/api/trader/addNewProduct", addProduct,
            {headers: this.localData.getHeaders()}
        );
    }

    //Save  Excluded  Product Details
    saveNewProduct(userForm: UntypedFormGroup) {
        return this.http.post<allShopProductDetails[]>(
            this.endpoint + "/api/store/product", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get Exclusion Product Category Details
    getCategory() {
        return this.http.get<MainCategory[]>(
            this.endpoint + "/api/category/main/getAll",
            {headers: this.localData.getHeaders()}
        );
    }

    // Login Credential Check
    loginCheck() {
        return this.http.get<UserDetails>(
            this.endpoint + "/api/user/getAll",
            {headers: this.localData.getHeaders()}
        );
    }

    // login request check
    login(loginRequest: LoginRequest) {
        let headers;
        if (loginRequest.userName == 'varnikstores') {
            headers = this.localData.signInAnadhamHeaders();
        } else {
            headers = this.localData.signinHeaders();
        }
        return this.http.post<LoginResponse>(
            this.endpoint + "/auth/login",loginRequest,
            {headers: headers}
        );
    }

    //all shop details API
    getShopDetails() {
        let distributorId = localStorage.getItem("distributorId")
        console.log("distributorId::", distributorId)
        return this.http.get<shopDetails[]>(
            this.endpoint + "/api/trader/getAll/" + distributorId,
            {headers: this.localData.getHeaders()}
        )
    }

    //get Sub Category by Main Category ID
    getSubCatID(catId) {
        return this.http.get<SubCategory>(
            this.endpoint + "/api/category/sub/getByMain/" + catId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get Product Details By Sub Category ID
    getProductDetails(subCatId) {
        return this.http.get<productDetails[]>(
            this.endpoint + "/api/product/subCategory/" + subCatId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Get Shop Product details Based By Shop ID
    getShopProductDetails(traderId: number) {
     /*   let userId = localStorage.getItem("userId")
        let distributorId = localStorage.getItem("distributorId")
        console.log("userId::", distributorId)*/
        return this.http.get<allShopProductDetails>(
            this.endpoint + "/api/store/getByTrader/" + traderId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Save  Excluded  Product Details
    saveProduct(userForm) {
        return this.http.post<allShopProductDetails[]>(
            this.endpoint + "/api/trader/product/add", userForm,
            {headers: this.localData.getHeaders()}
        )
    }

    // Logo File Upload
    shopLogoUpload(formData: FormData, shopId: number) {
        return this.http.post<any>
        (this.endpoint + "/api/shop/picture/upload?shopId=" + shopId, formData,
            {headers: this.localData.getImageHeaders()}
        )
    }

    // Get Shop Logo Image
    getShopLogoImage(shopId: number) {
        return this.http.get(
            this.endpoint + "/api/shop/profile/" + shopId,
            {headers: this.localData.getHeaders(), responseType: "blob"}
        )
    }

    //Product Image file upload
    productImageUpload(formData: FormData, productId: number) {
        return this.http.post<String[]>(this.endpoint +
            "/api/products/picture/upload?productId=" + productId, formData,
            {headers: this.localData.getImageHeaders()}
        )
    }

    // Vendor Order Details
    getVendorOrders(shopId: number) {
        console.log("shop ID::", shopId)
        return this.http.get<VendorOrderDetails>(
            this.endpoint + "/vendor/orders/orderByShop/" + shopId,
            {headers: this.localData.getHeaders()}
        )
    }

    //Update Order Status
    updateOrderStatus(formGroup: UntypedFormGroup) {
        return this.http.patch<VendorOrderDetails>(
            this.endpoint + "/api/order/updateStatus", formGroup,
            {headers: this.localData.getHeaders()}
        )
    }

    //Update Product SRP
    /*updateProductSRP(product: any) {
        return this.http.post<allShopProductDetails>(
            this.endpoint + "/api/trader/updateProduct", product,
            {headers: this.localData.getHeaders()}
        )
    }*/


   /*//update product
    updateProduct(product: any) {
        return this.http.put<allShopProductDetails>(
            this.endpoint + "/api/store/product", product,
            {headers: this.localData.getHeaders()}
        )
    }*/

    updateProduct(productMRP, productSRP, productId) {
        return this.http.get<allShopProductDetails>(
            this.endpoint + "/api/store/product/" + productId + "/" + productSRP + "/" + productMRP,
            {headers: this.localData.getHeaders()}
        )
    }

    //getDistributorId
    getDistributorId() {
        let userId = localStorage.getItem("userId");
        return this.http.get<DistributorDetails>(
            this.endpoint + "/api/distributor/getById/"+ userId,
        {headers: this.localData.getHeaders()}
        )
    }

    //get Order details
    getOrderDetailsByDistributor() {
        return this.http.get<DistributorOrderDetails[]>(
            this.endpoint + "/api/distributor/getOrderFromRetailers",
        {headers: this.localData.getHeaders()}
        )
    }

    //get Order details
    getOrderDetailsBySuperAdmin() {
        return this.http.get<Order[]>(
            this.endpoint + "/super-admin/getAllOrders",
            {headers: this.localData.getHeaders()}
        )
    }

    deliverDateUpdate(orderId,deliveryDate) {
        return this.http.get(
            this.endpoint + "/api/distributor/updateDeliveryDate/" + orderId + "/" + deliveryDate,
            {headers: this.localData.getHeaders()}
        )
    }

    paymentStatusUpdate(orderId,paymentStatus) {
        return this.http.get(
            this.endpoint + "/api/distributor/updatePaymentStatus/" + orderId + "/" + paymentStatus,
            {headers: this.localData.getHeaders()}
        )
    }

    orderStatusUpdate(orderId,orderStatus) {
        return this.http.get(
            this.endpoint + "/api/distributor/updateOrderStatus/" + orderId + "/" + orderStatus,
            {headers: this.localData.getHeaders()}
        )
    }

    invoiceDetails(retailerOrderId: any) {
        return this.http.post<InvoiceDetails[]>(
            this.endpoint + "/api/distributor/makeInvoice", retailerOrderId,
            {headers: this.localData.getHeaders()}
        )
    }
}

