import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';

import {CommonModule, DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserComponent} from '@modules/main/header/user/user.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {MainMenuComponent} from './pages/main-menu/main-menu.component';
import {SubMenuComponent} from './pages/main-menu/sub-menu/sub-menu.component';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {ControlSidebarComponent} from './modules/main/control-sidebar/control-sidebar.component';
import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import {SidebarSearchComponent} from './components/sidebar-search/sidebar-search.component';
import {ShopRegister} from '@pages/shop-register/shopregister.component';
import {ShopOwnerRegisterComponent} from '@pages/shop-owner-register/shop-owner-register.component';
import {productInclusion} from '@pages/inclusion/inclusion.component';
import {Exclusion} from '@pages/exclusion/exclusion.component';
import {ConfigLoader} from "@services/ConfigLoader";
import {ConfigService} from "@services/configService";
import {APP_INITIALIZER} from "@angular/core";
import {MyShopProductComponent} from "@pages/my-shop-products/my-shop-product.component";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {NgxSpinnerModule} from "ngx-spinner";
import {OrderProcessingComponent} from '@pages/order-processing/order-processing.component';
import {InVoicePrintComponent} from '@pages/invoice-print/invoice-print.component';
import {PrintDataService} from "@services/printDataService";
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TraderOrderProcessingComponent} from "@pages/trader order processing/trader-order-processing.component";
import {PaymentComponent} from "@pages/payment/payment.component";
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';


defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoginComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        RegisterComponent,
        DashboardComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        ShopRegister,
        ShopOwnerRegisterComponent,
        OrderProcessingComponent,
        TraderOrderProcessingComponent,
        InVoicePrintComponent,
        productInclusion,
        Exclusion,
        MyShopProductComponent,
        PaymentComponent
    ],

    imports: [
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        FormsModule,
        AppRoutingModule,
        Ng2SearchPipeModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        ProfabricComponentsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpClient) => {
                    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
                },
                deps: [HttpClient]
            }
        }),
        NgMultiSelectDropDownModule,
        NgxSpinnerModule.forRoot({type: 'square-jelly-box'}),
        NgMultiSelectDropDownModule,
        NgMultiSelectDropDownModule,
        Ng2SearchPipeModule,
        NgMultiSelectDropDownModule,
        MatButtonModule,
        MatCardModule,
        Ng2SearchPipeModule

    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: ConfigLoader,
            deps: [ConfigService],
            multi: true,
        },
        ConfigService,
        PrintDataService,
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
